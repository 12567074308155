<template>
    <b-row class="wit-range">
        <b-col cols="2">
            <b-form-input
                v-model="localValue"
                type="number"
                :min="min"
                :max="max"
                number
                @input="onInput"
                :disabled="disabled"
                :key="key"
            ></b-form-input>
        </b-col>
        <b-col cols="8" class="vue-slider-col">
            <vue-slider v-model="localValue" :min="min" :max="max" @change="onInput" :disabled="disabled" />
            <slot name="feedback" />
        </b-col>
    </b-row>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        id: {
            type: String,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 30,
        },
    },
    components: {
        VueSlider,
    },
    watch: {
        value() {
            this.localValue = this.value
        },
    },
    created() {
        if (this.value) {
            this.localValue = this.value
        }
    },
    data() {
        return {
            localValue: 0,
            timeout: null,
            key: 0,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.localValue)
        },

        onInput(value) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            if (isNaN(value) || value.toString().trim() === '' || Number(value) < this.min) {
                this.timeout = setTimeout(() => {
                    this.localValue = this.min
                    this.emitInput()
                    this.key = 1
                    this.$nextTick(() => (this.key = 0))
                }, 500)
            }

            this.localValue = Math.max(Math.min(Number(value), this.max), this.min)
            this.emitInput()
        },
    },
}
</script>

<style lang="scss">
.vue-slider-col {
    padding-top: 8px;
    z-index: 0;
}
</style>
